.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1440px;
  padding-top: 80px;
  padding-right: 55px;
  padding-bottom: 100px;
  padding-left: 55px;
}

.title {
  font-size: 2em !important;
  margin-bottom: 0.5em;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footer-area {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 5em;
}

.contact-section {
  margin-left: auto;
  margin-bottom: 100px;
}

.header-container-contact-info {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  margin-left: 15px;
}

.header-container-contact-info ul {
  display: flex;
  gap: 30px;
}
ul {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 25px;
  color: white;
  position: relative;
  user-select: none;
}

ul li {
  display: flex;
  align-items: center;
}
.contact-lang-text {
  color: #fff;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.list-item {
  margin: 0 25px 0px 30px;
  width: 2px;
  height: auto;
  background-color: white;
}

.select-buttons {
  position: absolute;
  top: 50px;
  left: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  color: black;
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 5px;
  cursor: pointer;
}

.select-wrapper {
  position: relative;
  color: #fff;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  cursor: pointer;
}

.select-container {
  position: absolute;
  padding: 5px;
  top: 35px;
  left: 0px;
  font-size: 28px;
  font-weight: 800;
  width: 100%;
  background-color: white;
  margin-top: 25px;
  border-radius: 5px;
  color: black;
}

.select-options {
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  display: inline-block;
}

/* intro section */
.intro-section {
  padding: 3em;
  background-color: white;
}

.intro-section-header {
  color: #99b93a;
  font-family: Inter;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.intro-section-paragraph {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.intro-section-paragraph p {
  color: #1c2a3f;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.intro-section-paragraph a {
  color: #99b93a;
  font-family: Inter;
  font-size: 24px;
  font-style: bold;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
/* info section */
.info-section {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  align-items: center;
  justify-content: space-between;
  margin: 5em 0;
  position: relative;
}

.bg-svg {
  background-color: white;
  clip-path: polygon(0 0, 100% 19%, 100% 68%, 0% 100%);
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 1205px;
  z-index: -1;
}

.title-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 3em;
  padding-right: 0;
}
.info-section-header {
  color: #99b93a;
  font-family: Inter;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.tech-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-tech {
  color: #1c2a3f;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  display: block;
}
.tech-line {
  fill: #1c2a3f;
  stroke-width: 1px;
  stroke: #1c2a3f;
  margin: 0 1.5em;
  height: 230px;
}
.tech-line svg {
  height: 230px;
}

.tech-content {
  color: #1c2a3f;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.info-content {
  display: block;
  color: #1c2a3f;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tech-image {
  margin-left: 80px;
  margin-top: -110px;
}

.tech-image img {
  height: auto;
  width: 100%;
}

/* footer-section */

.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-line svg {
  margin-bottom: 20px;
  height: 100%;
  width: 100%;
  padding: 0 15px 0 15px;
}
.footer-content {
  display: flex;
  justify-content: space-between;
}
.footer-title-paragraph {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.footer-title {
  flex: 1;
  margin-right: 0;
}
.footer-icon {
  display: flex;
  align-items: center;
}

.footer-icon a {
  display: flex;
  margin-left: 2em;
}

/* media queries */
@media (max-width: 1208px) {
  .tech-image {
    display: none;
  }
}
@media (max-width: 715px) {
  .footer-content {
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .footer-title-paragraph {
    font-size: 15px;
  }
  .footer-title {
    margin: 0;
  }
  .footer-icon {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    font-size: 15px;
  }
  .footer-icon ul {
    margin: 0;
  }
  .tech-line svg {
    height: 200px;
  }
}

@media (max-width: 625px) {
  .tech-container {
    width: 100%;
  }
  .footer-area {
    flex-direction: column;
  }
  .footer-icon a {
    display: flex;
    margin: 2em;
    margin-bottom: 0;
  }
  .title {
    width: 100%;
    font-size: 1.5em !important;
    margin: 0 !important;
    padding: 0 !important;
    padding-bottom: 1em !important;
  }
  .intro-section,
  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5em;
  }
  .header-container-contact-info {
    font-size: 30px;
  }
  .header-container-contact-info li {
    font-size: 25px;
  }
  .header-container-contact-info ul {
    gap: 15px;
  }
  .list-item {
    margin: 0 15px 0px 15px;
  }
  .contact-info--logo {
    font-size: 10px;
  }
  .intro-section-header {
    font-size: 35px;
  }
  .info-section-header {
    font-size: 15px;
  }
  .intro-section-paragraph p {
    font-size: 15px;
  }
  .intro-section-paragraph i {
    font-size: 15px;
  }
  .intro-section-paragraph a {
    font-size: 15px;
  }
  .bg-svg {
    clip-path: none;
  }
  .header-container--logo img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
  }
  .contact-section {
    margin-left: 0;
  }
  .title-container {
    gap: 0;
  }
  .tech-image {
    display: none;
  }
  .info-content {
    font-size: 15px;
  }
  .info-tech {
    font-size: 15px;
  }
  .tech-line {
    height: 170px;
  }
  .tech-line svg {
    height: 170px;
  }
}
